import React from "react";
import { Card } from "../../../../components/mols/Card";
import { Loader } from "../../../../components/atoms/Loader";

interface ProductCardSkeletonProps {
  id: number;
}

export const ProductCardSkeleton: React.FC<ProductCardSkeletonProps> = ({
  id,
}) => {
  return (
    <Card
      id={`prd-skel-${id}`}
      key={`prd-skel-${id}`}
      className="w-100 mb-3"
      bodyClass="d-flex flex-column justify-content-between"
    >
      <div>
        <h1 className="h5 text-center text-muted">
          <span className="skeleton-lg"></span>
          <span className="skeleton-sm"></span>
        </h1>
        <h2 className="h1 text-center text-bold">
          <span className="skeleton"></span>
        </h2>
      </div>
      <p className="text-justify">
        <span className="skeleton-sm"></span>
        <span className="skeleton"></span>
        <span className="skeleton"></span>
        <span className="skeleton-sm"></span>
        <span className="skeleton-sm"></span>
      </p>
      <button className="w-100 btn btn-primary" disabled>
        <Loader />
      </button>
    </Card>
  );
};
