import React from "react";
import { Modal } from "../../../../components/mols/Modal";

interface OrderTypeModalProps {
  isShown: boolean;
  onClose: () => void;
  onSelect: (renewal: boolean) => void;
}

export const OrderTypeModal: React.FC<OrderTypeModalProps> = ({
  isShown,
  onClose,
  onSelect,
}) => {
  return (
    <Modal
      isShown={isShown}
      title="Qual o tipo do pedido?"
      body="É uma licença ou renovação?"
      footer={
        <div className="w-100 d-flex justify-content-center gap-2">
          <button className="btn btn-primary" onClick={() => onSelect(false)}>
            Nova licença
          </button>
          <button className="btn btn-success" onClick={() => onSelect(true)}>
            Renovação
          </button>
          <button className="btn btn-secondary" onClick={onClose}>
            Cancelar
          </button>
        </div>
      }
      onClose={onClose}
    />
  );
};
