/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MainTemplate } from "../../../../components/templates/MainTemplate";
import { Card } from "../../../../components/mols/Card";
import { CustomerFilter } from "./CustomerFilter";
import { OrderTypeModal } from "./OrderTypeModal";
import { QuotationModal } from "./QuotationModal";
import { CustomerTable } from "./CustomerTable";
import { Customer } from "../../../../models/Customer";
import { useForm } from "../../../../hooks/formHook";
import { getAuthUser } from "../../../../utils/authUtils";
import { storage } from "../../../../utils/storage";
import { useNavigate } from "react-router-dom";
import { Order } from "../../../../models/Order";

interface OrderSelectCustomerProps {}

interface Filter {
  customer: string;
}

export const OrderSelectCustomer: React.FC<OrderSelectCustomerProps> = () => {
  const resellerId = getAuthUser()?.resellerId ?? null;
  const navigate = useNavigate();

  const [showOrderTypeModal, setShowModal] = useState<boolean>(false);
  const [showQuotationModal, setShowQuotationModal] = useState<boolean>(false);
  const [isRenewal, setIsRenewal] = useState<boolean>(false);
  const [isCustomerEnabled, setIsCustomerEnabled] = useState<boolean>(false);

  const [customer, setCustomer] = useState<Customer | null>(null);
  const [filter, setFilter] = useState<Filter>({ customer: "" });
  const [normalizedFilter, setNormalizedFilter] = useState<string>("");

  const { handleInputChange } = useForm<Filter>({ setFormData: setFilter });

  const toggleModal = () => {
    if (!customer) return;

    let canRenew: boolean = true;
    let canStartANewOrder: boolean = true;

    if (!customer.cotermDate) {
      canRenew = false;
      canStartANewOrder = true;
    } else {
      const cotermDate = new Date(customer.cotermDate);
      const today = new Date();
      const differenceInMs = today.getTime() - cotermDate.getTime();
      const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

      if (customer.hasPendingRenewal) {
        canRenew = false;
        canStartANewOrder = false;
      } else if (differenceInDays < -30) {
        canRenew = false;
        canStartANewOrder = true;
      } else if (differenceInDays <= 0) {
        canRenew = true;
        canStartANewOrder = true;
      } else if (differenceInDays <= 60) {
        canRenew = false;
        canStartANewOrder = true;
      } else {
        canRenew = false;
        canStartANewOrder = false;
      }
    }

    let canDoAnything: boolean = canRenew || canStartANewOrder;
    setIsCustomerEnabled(canDoAnything);

    if (canRenew) {
      setShowModal((prevShowModal) => !prevShowModal);
    } else {
      setIsRenewal(false);
      setShowQuotationModal((prevShowModal) => !prevShowModal);
    }
  };

  const openQuotationModal = (renewal: boolean) => {
    setIsRenewal(renewal);
    setShowModal(false);
    setShowQuotationModal(true);
  };

  const toggleQuotationModal = () => {
    setShowQuotationModal((prevShowModal) => !prevShowModal);
  };

  const handleFilter = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const normalizedCustomer = filter.customer.trim().toLocaleLowerCase();
    setNormalizedFilter(normalizedCustomer);
  };

  useEffect(() => {
    const currentOrder = storage.get<Order>("currentOrder");

    if (!currentOrder) return;

    const url =
      currentOrder.hubspotLink || currentOrder.isRenewal
        ? "/order/cart"
        : "/order/products";
    navigate(url);
  }, []);

  return (
    <MainTemplate>
      <Card className="mb-3">
        <CustomerFilter
          id="customer"
          value={filter.customer}
          onInputChange={handleInputChange}
          onFilter={handleFilter}
        />
      </Card>

      <Card>
        <CustomerTable
          filter={normalizedFilter}
          resellerId={resellerId}
          setCustomer={setCustomer}
        />

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            onClick={toggleModal}
            disabled={customer === null}
          >
            Avançar
          </button>
        </div>
      </Card>

      <OrderTypeModal
        isShown={showOrderTypeModal}
        onClose={toggleModal}
        onSelect={openQuotationModal}
      />

      <QuotationModal
        isShown={showQuotationModal}
        isRenewal={isRenewal}
        isThreeYears={customer?.isThreeYears ?? true}
        customerId={customer?.id ?? null}
        isEnabled={isCustomerEnabled}
        onClose={toggleQuotationModal}
      />
    </MainTemplate>
  );
};
