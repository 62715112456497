import React, { useEffect, useRef } from "react";
import { Config } from "datatables.net-dt";
import ptbr from "../../../../assets/lib/js/dataTables/pt-br.json";
import $ from "jquery";
import { getCustomersByReseller } from "../../../../services/api/CustomerService";
import { Customer } from "../../../../models/Customer";
import { useToast } from "../../../../components/atoms/Toast";

interface CustomerTableProps {
  filter: string;
  resellerId: number | null;
  setCustomer: (data: Customer) => void;
}

export const CustomerTable: React.FC<CustomerTableProps> = ({
  filter,
  resellerId,
  setCustomer,
}) => {
  const tableRef = useRef<any>(null);
  const dataTableInitialized = useRef<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    // Reinitializes filters on change
    $.fn.dataTable.ext.search = [];

    $.fn.dataTable.ext.search.push((settings: any, data: string[]) => {
      return data.some((value) => value?.toLowerCase().includes(filter));
    });

    if (dataTableInitialized.current) {
      const table = $(tableRef.current).DataTable();
      table.page("first").draw();
    }
  }, [filter]);

  useEffect(() => {
    if (!tableRef.current || dataTableInitialized.current) return;
    const currentTable = tableRef.current;

    const dataTableOptions: Config = {
      dom: "<'mb-2'l>rt<'row'<'col-sm-12 my-2 d-flex justify-content-center'p>>",
      language: ptbr,
      columns: [
        { data: "customerId", width: "7em", className: "text-start" },
        { data: "name" },
        {
          data: "customerContacts",
          width: "20%",
          render: (data) => {
            if (!data || !Array.isArray(data)) return "";
            const contact = data.find(
              (contact) => contact.typeContact === "Signature"
            );
            return contact ? contact.email : "";
          },
        },
        {
          data: "resellerAdobeId",
          width: "7em",
          className: "text-start",
        },
        { data: "resellerName", width: "15%", className: "text-start" },
      ],
      scrollCollapse: true,
      scrollY: "calc(100vh - 400px)",
      ordering: false,
      processing: true,
      autoWidth: true,
      serverSide: true,
      ajax: (data: any, callback: (data: any) => void) => {
        const { draw, start, length } = data;
        const page = Math.floor(start / length);

        getCustomersByReseller(resellerId, page, length, filter)
          .then((res) => {
            callback({
              draw,
              recordsTotal: res.recordsTotal,
              recordsFiltered: res.recordsFiltered,
              data: res.data,
            });
          })
          .catch((error) => {
            toast.fire({
              icon: "error",
              title: "Erro ao buscar clientes",
              text: error,
            });
          });
      },
    };

    const table = $(currentTable).DataTable(dataTableOptions);
    dataTableInitialized.current = true;

    $(currentTable).on("click", "tbody tr", function () {
      const rowData = table.row(this).data();
      if (rowData) setCustomer(rowData);

      $(currentTable).find("tbody tr").removeClass("primary-bg");
      $(this).addClass("primary-bg");
    });

    return () => {
      if ($.fn.dataTable.isDataTable(currentTable)) {
        $(currentTable).DataTable().destroy();
        dataTableInitialized.current = false;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCustomer, filter, tableRef]);

  return (
    <table
      ref={tableRef}
      className="table table-sm table-striped table-select"
      style={{ maxHeight: "calc(100vh - 230px)" }}
    >
      <thead>
        <tr>
          <th className="text-start">Customer ID</th>
          <th className="text-start">Nome</th>
          <th className="text-start">E-mail</th>
          <th className="text-start">Reseller ID</th>
          <th className="text-start">Revenda</th>
        </tr>
      </thead>
    </table>
  );
};
