/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useMemo, useEffect } from "react";
import { MainTemplate } from "../../../../components/templates/MainTemplate";
import { Card } from "../../../../components/mols/Card";
import { ProductInfoModal } from "./ProductInfoModal";
import { SearchProduct } from "./SearchProduct";
import { CustomerDetails } from "./CustomerDetails";
import { CustomerProduct } from "../../../../models/Product";
import { storage } from "../../../../utils/storage";
import { Order } from "../../../../models/Order";
import { ProductList } from "./ProductList";
import { getOrderById } from "../../../../services/api/OrderService";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { useToast } from "../../../../components/atoms/Toast";
import { Customer } from "../../../../models/Customer";
import {
  RefreshedSku,
  RefreshSkuItem,
  toRefreshSkuItemList,
} from "../../../../models/RefreshSku";
import { refreshCart, updateSkuList } from "../../../../services/cartService";
import { useNavigate } from "react-router-dom";
import { OrderItem } from "../../../../models/OrderItem";

export const OrderSelectProduct: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const isFirstLoad = useRef<boolean>(true);

  // Consolidating reading data from localStorage
  const initialData = useMemo(() => {
    const currentOrder = storage.get<Order>("currentOrder");

    if (!currentOrder) {
      handleError(
        new Error("Você será redirecionado para a tela de pedidos"),
        toast,
        "ao identificar seu pedido"
      );
      navigate("/order/customer");
      return {
        order: null,
        customerId: 0,
        skuList: [],
        licenseLevel: "",
        transactionLevel: "",
        customer: null,
      };
    }

    if (currentOrder.isHubspotQuote || currentOrder.isRenewal) {
      navigate("/order/cart");
      return {
        order: null,
        customerId: 0,
        skuList: [],
        licenseLevel: "",
        transactionLevel: "",
        customer: null,
      };
    }

    const { customer } = currentOrder;

    return {
      order: currentOrder,
      customerId: currentOrder.customerId ?? 0,
      skuList: storage.get<RefreshSkuItem[]>("skuList") ?? [],
      licenseLevel:
        storage.get<string>("licenseLevel") || customer?.licenseLevel || "",
      transactionLevel:
        storage.get<string>("transactionLevel") ||
        customer?.transactionLevel ||
        "",
      customer: customer ?? null,
    };
  }, [handleError, navigate, toast]);

  // Using the initial values ​​for the states
  const [order, setOrder] = useState<Order>(initialData.order as Order);
  const [customerId] = useState<number>(initialData.customerId);
  const [skuList, setSkuList] = useState<RefreshSkuItem[]>(initialData.skuList);
  const [licenseLevel, setLicenseLevel] = useState<string>(
    initialData.licenseLevel
  );
  const [transactionLevel, setTransactionLevel] = useState<string>(
    initialData.transactionLevel
  );
  const [customer] = useState<Customer | null>(initialData.customer);

  const [selectedProduct, setSelectedProduct] =
    useState<CustomerProduct | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");

  const updateOrder = (newOrder: Order) => {
    setOrder(newOrder);
    storage.set("currentOrder", newOrder);
    if (newOrder.isHubspotQuote) navigate("order/cart");
  };

  const updateSkus = (items: OrderItem[]) => {
    const skus = toRefreshSkuItemList(items);
    storage.set("skuList", skus);
    setSkuList(skus);
  };

  const handleOpenProduct = (product: CustomerProduct) => {
    setSelectedProduct(product);
    setIsModalVisible(true);
  };

  const handleBuyProduct = async (product: RefreshSkuItem): Promise<void> => {
    try {
      const selectedProducts: RefreshSkuItem[] =
        product.productId !== selectedProduct?.id
          ? skuList.filter((prod) => prod.productId !== selectedProduct?.id)
          : skuList;

      const orderId = order?.id ?? 0;

      const updatedSkuList: RefreshSkuItem[] = updateSkuList(
        product,
        selectedProducts
      );

      const cart: RefreshedSku = await refreshCart(orderId, updatedSkuList);

      setLicenseLevel(cart.licenseLevel);
      setTransactionLevel(cart.transactionLevel);

      const newOrder = await getOrderById(orderId);
      updateOrder(newOrder);
      updateSkus(newOrder.items);

      setIsModalVisible(false);
    } catch (error) {
      handleError(error, toast, "atualizar o carrinho de compras");
    }
  };

  useEffect(() => {
    const orderId = order?.id ?? storage.get<Order>("currentOrder")?.id;

    if (!orderId) navigate("/order/customer");

    getOrderById(orderId)
      .then((curOrder) => {
        if (!curOrder) return;
        updateSkus(curOrder.items);
      })
      .catch((error) => {
        handleError(error, toast, "buscar os dados do pedido");
      });
  }, []);

  return (
    <MainTemplate currentOrder={order}>
      <Card className="mb-3">
        <SearchProduct onFilterChange={setFilter} />
      </Card>

      <Card className="mb-3">
        <CustomerDetails
          customerId={customer?.customerId}
          name={customer?.name}
          prorata={customer?.proRata}
        />
      </Card>

      <ProductList
        key={
          "prdList" +
          (licenseLevel ? `-${licenseLevel}` : "") +
          (transactionLevel ? `-${transactionLevel}` : "")
        }
        skuList={skuList}
        customer={customer}
        customerId={customerId}
        filter={filter}
        isFirstLoad={isFirstLoad}
        licenseLevel={licenseLevel}
        transactionLevel={transactionLevel}
        onOpenProduct={handleOpenProduct}
      />

      {selectedProduct && (
        <ProductInfoModal
          product={selectedProduct}
          isShow={isModalVisible}
          orderId={order?.id ?? 0}
          purchasedSkus={skuList}
          onClose={() => setIsModalVisible(false)}
          onBuy={handleBuyProduct}
        />
      )}
    </MainTemplate>
  );
};
