import React from "react";
import { InputNumber } from "../../../../../components/atoms/InputNumber";

interface FormQuantityProps {
  value: number;
  onChange: (value: number) => void;
}

export const FormQuantity: React.FC<FormQuantityProps> = ({
  value,
  onChange,
}) => {
  const minQuantity = 0;
  const maxQuantity = 1000000;

  return (
    <div className="form-group mb-2">
      <label htmlFor="quantity" className="mb-2">
        Quantidade
      </label>
      <InputNumber
        id="quantity"
        name="quantity"
        value={value}
        onChange={onChange}
        minValue={minQuantity}
        maxValue={maxQuantity}
      />
    </div>
  );
};
