import React, { useCallback, useEffect, useRef } from "react";
import { Card } from "../../../components/mols/Card";
import { useNavigate } from "react-router-dom";
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";
import DataTable, { DataTableRef } from "datatables.net-react";
import DT from "datatables.net-dt";
import { useToast } from "../../../components/atoms/Toast";
import { getOrdersHistory } from "../../../services/api/OrderService";
import {
  orderStatus,
  toOrderStatusText,
} from "../../../models/enums/orderStatus";
import { OrderHistoryFilterRequest } from "./OrdersHistoryList";

import "datatables.net-bs5";
import "datatables.net-responsive";
import "datatables.net-buttons-bs5";

import JSZip from "jszip";
import $ from "jquery";
import { OrderHistoryResponse } from "../../../models/OrderHistory";
import { formatMoneyBRL } from "../../../utils/currency";
import { toOrderTypeText } from "../../../models/enums/orderType";
// @ts-ignore
window.JSZip = JSZip;

DataTable.use(DT);

function mapOrderHistoryResponse(
  apiData: OrderHistoryResponse[]
): OrderHistoryResponse[] | null {
  if (!(apiData.length > 0)) {
    return null;
  }

  return apiData.map((item) => ({
    id: item.id,
    customerIdAdobe: item.customer.split(" - ")[0]?.trim() || "",
    reseller: item.reseller,
    customer: item.customer,
    orderDate: `${new Date(item.orderDate).toLocaleDateString("pt-BR")}`,
    totalValue: item.totalValue,
    pedCustomer: item.pedCustomer,
    pedReseller: item.pedReseller,
    pedAdobe: item.pedAdobe,
    type: item.type,
    status: item.status as orderStatus,
  }));
}

interface OrdersHistoryDataTableProps {
  filters: OrderHistoryFilterRequest;
}

export const OrdersHistoryDataTable: React.FC<OrdersHistoryDataTableProps> = ({
  filters,
}) => {
  const Toast = useToast();
  const navigate = useNavigate();
  const dataTableRef = useRef<DataTableRef>(null);

  const handleDetail = useCallback(
    (itemId: number) => {
      navigate(`/orders/status/${itemId}`);
    },
    [navigate]
  );

  const loadTableRef = useRef<(data: any, callback: any) => void>();

  const loadTable = useCallback(
    async (data: any, callback: any) => {
      const { start, length, draw } = data;
      const currentPage = Math.floor(start / length);

      const newFilters = { ...filters, page: currentPage, size: length };

      try {
        const result = await getOrdersHistory(newFilters);

        const mappedData = mapOrderHistoryResponse(result?.content ?? []);

        callback({
          draw: draw,
          recordsTotal: result?.total,
          recordsFiltered: result?.total,
          data: mappedData,
        });
      } catch (error) {
        if (error instanceof Error) {
          Toast.fire({
            icon: "error",
            title: error.message,
          });
        }

        callback({
          draw: draw,
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
        });
      }
    },
    [Toast, filters]
  );

  useEffect(() => {
    loadTableRef.current = loadTable;
  }, [filters, loadTable]);

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.dt()?.ajax.reload();
    }
  }, [filters]);

  return (
    <Card>
      <DataTable
        ref={dataTableRef}
        className="table table-sm table-striped"
        options={{
          dom:
            "<'row my-2'<'col-4'B><'col-4 text-center'i><'col-4'f>>" +
            "rt<'row'<'col-12 my-2 d-flex justify-content-center'p>",
          buttons: [
            {
              extend: "excelHtml5",
              text: '<i class="fa fa-file-excel"></i>',
              filename: "Importacoes",
              className: "btn btn-success",
              exportOptions: {
                columns: ":visible",
              },
            },
            {
              extend: "csvHtml5",
              text: '<i class="fa fa-file-csv"></i>',
              filename: "Importacoes",
              className: "btn btn-info",
              exportOptions: {
                columns: ":visible",
              },
              init: function (api, node) {
                $(node).css("margin-left", "5px");
              },
            },
          ],
          language: ptbr,
          scrollY: "calc(100vh - 87px - 13em)",
          lengthMenu: [
            [64, 128, 256, 512],
            [64, 128, 256, 512],
          ],
          ordering: false,
          serverSide: true,
          processing: true,
          lengthChange: false,
          pageLength: 20,
          ajax: (data: any, callback: any) => {
            if (loadTableRef.current) {
              loadTableRef.current(data, callback);
            }
          },
          initComplete: function () {
          
            $(document).on("click", ".detail-button", function () {
              const itemId = $(this).data("id");
              handleDetail(itemId);
            });
          },
        }}
        columns={[
          {
            data: "id",
            title: "Número de pedido",
            width: "6em",
            className: "text-start",
          },
          {
            data: "customerIdAdobe",
            title: "Customer ID",
            width: "6em",
            className: "text-start",
          },
          {
            data: "reseller",
            title: "Revenda",
            width: "20%",
            className: "text-start",
          },
          {
            data: "customer",
            title: "Cliente",
            width: "20%",
            className: "text-start",
          },
          {
            data: "orderDate",
            title: "Dt. Pedido",
            width: "6em",
            className: "text-center",
          },
          {
            data: "totalValue",
            title: "Valor Total",
            width: "6em",
            className: "text-end",
            render: (data: number) => formatMoneyBRL(data),
          },
           {
            data: "pedReseller",
            title: "Ped. Revenda",
            width: "6em",
            className: "text-center",
            render: (data: string) => data ?? "-",
          },
          {
            data: "pedCustomer",
            title: "Ped. Cliente",
            width: "6em",
            className: "text-center",
            render: (data: string) => data ?? "-",
          },
          {
            data: "pedAdobe",
            title: "Ped. Adobe",
            width: "6em",
            className: "text-center",
            render: (data: string) => data ?? "-",
          },
          {
            data: "type",
            title: "Tipo",
            width: "6em",
            className: "text-start",
            render: (data) => {
              return toOrderTypeText(data);
            },
          },

          {
            data: "status",
            title: "Status",
            width: "10%",
            className: "text-center",
            render: (data: string) => toOrderStatusText(data),
          },
          {
            data: null,
            title: "Ações",
            width: "6em",
            className: "text-center",
            orderable: false,
            render: (_: any, __: any, rowData: OrderHistoryResponse) => {
              return `<button class="btn btn-link detail-button" title="Ver Detalhes" data-id="${rowData.id}">
                        <i class="fa fa-search"></i>
                      </button>`;
            },
          },
        ]}
      />
    </Card>
  );
};
