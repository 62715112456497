import React from "react";
import { Modal } from "../../../../components/mols/Modal";
import { formatDocument } from "../../../../utils/formatDocument";
import { Loader } from "../../../../components/atoms/Loader";

interface ConfirmationModalProps {
  customerName: string;
  customerDocument: string;
  isShown: boolean;
  isLoading: boolean;
  onSave: () => void;
  onClose: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  customerName,
  customerDocument,
  isShown,
  isLoading,
  onSave,
  onClose,
}) => {
  return (
    <Modal
      isShown={isShown}
      onClose={onClose}
      title="Confirme os dados de faturamento"
    >
      <p>Confirme se o pedido deve mesmo ser faturado para o cliente abaixo:</p>

      <p>
        <b>Nome:</b> {customerName}
        <br />
        <b>CNPJ:</b> {formatDocument(customerDocument)}
      </p>

      <div className="hr"></div>

      <p className="text-muted">
        Caso os dados estejam errados, entre em contato com a equipe PARS.
      </p>

      <div className="d-flex justify-content-center gap-2">
        <button
          className="btn btn-success"
          onClick={onSave}
          disabled={isLoading}
        >
          Sim {isLoading && <Loader />}
        </button>
        <button
          className="btn btn-danger"
          onClick={onClose}
          disabled={isLoading}
        >
          Não {isLoading && <Loader />}
        </button>
        <button
          className="btn btn-secondary"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancelar {isLoading && <Loader />}
        </button>
      </div>
    </Modal>
  );
};
