/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useCallback } from "react";
import { ProductCard } from "./ProductCard";
import { Card } from "../../../../components/mols/Card";
import { useProducts } from "../../../../hooks/useProducts";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { useToast } from "../../../../components/atoms/Toast";
import { ProductCardSkeleton } from "./ProductCardSkeleton";

interface ProductListProps {
  skuList: any[];
  customer: any;
  customerId: any;
  filter: any;
  isFirstLoad: any;
  licenseLevel: string;
  transactionLevel: string;
  onOpenProduct: (product: any) => void;
}

export const ProductList: React.FC<ProductListProps> = ({
  skuList,
  customer,
  customerId,
  filter,
  isFirstLoad,
  licenseLevel,
  transactionLevel,
  onOpenProduct,
}) => {
  const mainAreaRef = useRef<HTMLElement | null>(null);
  const toast = useToast();
  const { handleError } = useErrorHandler();

  const { products, isLoading, hasMore, fetchProducts } = useProducts(
    customer,
    customerId
  );

  const handleScroll = useCallback(() => {
    if (!mainAreaRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = mainAreaRef.current;
    const scrollPosition = scrollTop + clientHeight;
    const threshold = scrollHeight * 0.75;

    // Stop here if not scrolled past 75% or conditions are not met
    if (scrollPosition < threshold || isLoading || !hasMore) return;

    fetchProducts(licenseLevel, transactionLevel);
  }, [isLoading, hasMore]);

  const scrollToTop = () => {
    document
      .getElementsByClassName("main-area")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    try {
      const mainAreaElement = document.querySelector(
        ".main-area"
      ) as HTMLElement;
      if (!mainAreaElement) return;

      mainAreaRef.current = mainAreaElement;

      // Checks if the main-area has scrolling available before adding the scroll event
      if (mainAreaElement.scrollHeight > mainAreaElement.clientHeight)
        mainAreaElement.addEventListener("scroll", handleScroll);

      // Clean up the scroll event listener on component unmount or dependencies change
      return () => {
        mainAreaElement.removeEventListener("scroll", handleScroll);
      };
    } catch (error) {
      handleError(error, toast, "listar os produtos");
    }
  }, [handleScroll]);

  useEffect(() => {
    fetchProducts(licenseLevel, transactionLevel, true, filter).then(() => {
      if (isFirstLoad.current) isFirstLoad.current = false;
    });
  }, [licenseLevel, transactionLevel, filter]);

  if (isLoading && products.length === 0)
    return (
      <div className="row">
        {Array.from({ length: 12 }).map((_, index) => (
          <div className="col col-3 d-flex" key={index}>
            <ProductCardSkeleton id={index} key={`skel-${index}`} />
          </div>
        ))}
      </div>
    );

  if (products.length === 0)
    return (
      <Card className="col col-12 text-center">Nenhum produto encontrado</Card>
    );

  return (
    <div className="row">
      {products.map((product) => {
        const existentProduct = skuList.find(
          (prd) => prd.productId === product.id
        );

        return (
          <div className="col col-3 d-flex" key={product.id}>
            <ProductCard
              id={product.id}
              key={`prdCard-${product.id}`}
              name={product.name}
              price={`US$ ${product.resellerPriceUSD.toFixed(2)}`}
              description={product.details}
              isSold={!!existentProduct}
              onBuy={() => onOpenProduct(product)}
            />
          </div>
        );
      })}

      {products.length > 20 && (
        <div>
          <button
            className="btn btn-secondary btn-lg rounded fade-in float-rb"
            onClick={scrollToTop}
          >
            <i className="fa fa-fw fa-angle-up"></i>
          </button>
        </div>
      )}
    </div>
  );
};
