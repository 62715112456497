import React, { useCallback, useRef, useState } from "react";
import { Icon } from "../../../components/atoms/Icon";
import { Input } from "../../../components/atoms/Input";
import { getAuthUser } from "../../../utils/authUtils";
import { Select } from "../../../components/atoms/Select";
import { orderStatusMapOptions } from "../../../models/enums/orderStatus";
import { useForm } from "../../../hooks/formHook";

export interface FormFilterListRma {
  startDate: string;
  endDate: string;
  customerFilter: string;
  resellerFilter: string;
  customerIdAdobe: string;
  rmaCode: string;
  pedAdobe: string;
  pedCustomer: string;
  pedReseller: string;
  status?: string;
}
export interface FilterListRmaProps {
  onSearch(form: FormFilterListRma): void;
}

const FilterListRma: React.FC<FilterListRmaProps> = ({ onSearch }) => {
  const isReseller = getAuthUser()!.profile.isReseller;

  const formRef = useRef<HTMLFormElement | null>(null);
  const [formFilters, setFormFilters] = useState<FormFilterListRma>({
    startDate: "",
    endDate: "",
    customerFilter: "",
    resellerFilter: "",
    customerIdAdobe: "",
    rmaCode: "",
    pedAdobe: "",
    pedCustomer: "",
    pedReseller: "",
  });

  const { handleInputChange } = useForm({ setFormData: setFormFilters });

  const resetFilters = () => {
    setFormFilters({
      startDate: "",
      endDate: "",
      customerFilter: "",
      resellerFilter: "",
      customerIdAdobe: "",
      rmaCode: "",
      pedAdobe: "",
      pedCustomer: "",
      pedReseller: "",
    });
  };

  const handleSearch = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      let status = formFilters.status !== "-1" ? formFilters.status : undefined;
      onSearch({ ...formFilters, status });
    },
    [formFilters, onSearch]
  );

  return (
    <form ref={formRef} noValidate onSubmit={handleSearch}>
      <div className="row">
        {!isReseller && (
          <div className="col col-md-4 col-12">
            <Input
              label="Revenda"
              placeholder="Pesquise pelo nome ou CNPJ ou Reseller ID da Revenda"
              type="text"
              id="resellerFilter"
              value={formFilters.resellerFilter}
              onChange={handleInputChange}
            />
          </div>
        )}

        <div className={`col col-md-4 col-12`}>
          <Input
            id="customerFilter"
            label="Cliente"
            placeholder="Pesquise pelo nome ou CNPJ ou CPF ou Customer ID do cliente"
            type="text"
            value={formFilters.customerFilter}
            onChange={handleInputChange}
          />
        </div>
        <div className={`col col-md-${!isReseller ? 3 : 4} col-12`}>
          <Select
            id="status"
            label="Status"
            options={orderStatusMapOptions()}
            value={formFilters.status}
            onChange={handleInputChange}
          />
        </div>

        <div className={`col col-md-${!isReseller ? 3 : 4} col-12`}>
          <Input
            label="Cód. RMA"
            placeholder="Cód. RMA"
            type="text"
            id="rmaCode"
            value={formFilters.rmaCode}
            onChange={handleInputChange}
          />
        </div>
        <div className="col col-md-3 col-6">
          <Input
            label="Ped. Adobe"
            placeholder="Ped. Adobe"
            type="text"
            value={formFilters.pedAdobe}
            onChange={(e) =>
              setFormFilters((st) => ({ ...st, pedAdobe: e.target.value }))
            }
          />
        </div>
        <div className="col col-md-3 col-6">
          <Input
            label="Ped. Cliente"
            placeholder="Ped. Cliente"
            type="text"
            value={formFilters.pedCustomer}
            onChange={(e) =>
              setFormFilters((st) => ({ ...st, pedCustomer: e.target.value }))
            }
          />
        </div>
        <div className="col col-md-3 col-6">
          <Input
            label="Ped. Revenda"
            placeholder="Ped. Revenda"
            type="text"
            value={formFilters.pedReseller}
            onChange={(e) =>
              setFormFilters((st) => ({ ...st, pedReseller: e.target.value }))
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-end gap-3">
        <button
          className="btn btn-secondary"
          id="clearFilter"
          type="reset"
          onClick={resetFilters}
        >
          Limpar Filtro
        </button>
        <button className="btn btn-primary" type="submit">
          Filtrar <Icon className="fa fa-fw fa-filter" />
        </button>
      </div>
    </form>
  );
};

export default FilterListRma;
