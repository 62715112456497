import React from "react";
import { Loader } from "../../../../../components/atoms/Loader";
import { formatMoneyBRL, formatMoneyUSD } from "../../../../../utils/currency";
import { OrderItem } from "../../../../../models/OrderItem";

interface TableItemProps {
  index: number;
  item: OrderItem;
  isHubspotQuote: boolean;
  isLoading: boolean;
  canChangeValue: boolean;
  onSelectItem: (item: OrderItem, type: "quantity" | "price") => void;
  onDeleteItem: (item: OrderItem) => void;
}

export const TableItem: React.FC<TableItemProps> = ({
  index,
  item,
  isHubspotQuote,
  isLoading,
  canChangeValue,
  onSelectItem,
  onDeleteItem,
}) => {
  const {
    product,
    quantity,
    resellerPriceUSD,
    invoicePriceUSD,
    totalInvoicePriceUSD,
    invoicePriceBRL,
    totalInvoicePriceBRL,
  } = item;

  const className: string = quantity > 0 ? "" : "error-bg";

  return (
    <tr>
      <td className={className}>{index}</td>
      <td className={className}>{product.license}</td>
      <td className={className}>{product.sku}</td>
      <td className={`text-end ${className}`}>
        {isHubspotQuote ? (
          quantity
        ) : isLoading ? (
          <>
            {quantity}
            <div className="btn-load">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {quantity}
            <button
              className="btn btn-sm edit-btn"
              data-id={product.sku}
              data-type="quantity"
              onClick={() => onSelectItem(item, "quantity")}
            >
              <i className="fa fa-fw fa-pen"></i>
            </button>
          </>
        )}
      </td>
      <td className={`border-start text-end ${className}`}>
        {formatMoneyUSD(resellerPriceUSD)}
      </td>
      <td className={`border-end text-end ${className}`}>
        {formatMoneyUSD(quantity * resellerPriceUSD)}
      </td>
      <td className={`border-start text-end ${className}`}>
        {formatMoneyUSD(invoicePriceUSD)}
      </td>
      <td className={`border-end text-end ${className}`}>
        {formatMoneyUSD(totalInvoicePriceUSD)}
      </td>
      <td className={`border-start text-end ${className}`}>
        {formatMoneyBRL(invoicePriceBRL)}
      </td>
      <td className={`border-end text-end ${className}`}>
        {!canChangeValue ? (
          formatMoneyBRL(totalInvoicePriceBRL)
        ) : isLoading ? (
          <>
            {formatMoneyBRL(totalInvoicePriceBRL)}
            <div className="btn-load">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {formatMoneyBRL(totalInvoicePriceBRL)}
            <button
              className="btn btn-sm edit-btn"
              data-id={product.sku}
              data-type="price"
              onClick={() => onSelectItem(item, "price")}
            >
              <i className="fa fa-fw fa-pen"></i>
            </button>
          </>
        )}
      </td>
      <td className={className}>
        {isHubspotQuote ? (
          ""
        ) : isLoading ? (
          <div className="btn-load">
            <Loader />
          </div>
        ) : (
          <button
            className="btn btn-sm delete-btn"
            data-id={product.sku}
            onClick={() => onDeleteItem(item)}
          >
            <i className="fa fa-fw fa-trash"></i>
          </button>
        )}
      </td>
    </tr>
  );
};
