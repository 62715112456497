import React from "react";
import { OrderItem } from "../../../../../models/OrderItem";
import { formatMoneyBRL, formatMoneyUSD } from "../../../../../utils/currency";
import { Order } from "../../../../../models/Order";
import { TableItem } from "./TableItem";

interface CartTableProps {
  order: Order;
  items: OrderItem[];
  isLoading: boolean;
  isHubspotQuote: boolean;
  canChangeValue: boolean;
  onSelectItem: (item: OrderItem, type: "quantity" | "price") => void;
  onDeleteItem: (item: OrderItem) => void;
}

export const CartTable: React.FC<CartTableProps> = ({
  order,
  items,
  isLoading,
  isHubspotQuote,
  canChangeValue,
  onSelectItem,
  onDeleteItem,
}) => {
  return (
    <table className="table table-sm table-striped mb-2">
      <thead>
        <tr>
          <th rowSpan={2}>#</th>
          <th rowSpan={2}>Produto</th>
          <th rowSpan={2}>SKU</th>
          <th rowSpan={2} className="text-start">
            Qtd.
          </th>
          <th colSpan={2} className="text-center border-start border-end">
            Revenda
          </th>
          <th colSpan={4} className="text-center border-start border-end">
            Faturamento
          </th>
          <th rowSpan={2}>&nbsp;</th>
        </tr>
        <tr>
          <th className="border-start">Unit. Tab U$</th>
          <th className="border-end">Total U$</th>
          <th className="border-start">Unit. Tab U$</th>
          <th className="border-end">Total U$</th>
          <th className="border-start">Unit. Tab R$</th>
          <th className="border-end">Total R$</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          return (
            <TableItem
              index={index + 1}
              item={item}
              isHubspotQuote={isHubspotQuote}
              isLoading={isLoading}
              canChangeValue={canChangeValue}
              onSelectItem={onSelectItem}
              onDeleteItem={onDeleteItem}
            />
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={4}>&nbsp;</th>
          <th className="text-end border-start">Total:</th>
          <th className="text-center boder-end">
            {formatMoneyUSD(order.resellerTotalPriceUSD)}
          </th>
          <th className="border-start">&nbsp;</th>
          <th className="text-center border-end">
            {formatMoneyUSD(order.invoiceTotalPriceUSD)}
          </th>
          <th className="border-start">&nbsp;</th>
          <th className="text-center border-end">
            {formatMoneyBRL(order.invoiceTotalPriceBRL)}
          </th>
          <th>&nbsp;</th>
        </tr>
      </tfoot>
    </table>
  );
};
