import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "../../../../components/mols/Modal";
import { CustomerProduct, toCustomerProduct } from "../../../../models/Product";
import { InputNumber } from "../../../../components/atoms/InputNumber";
import { refreshProducts } from "../../../../services/api/ProductService";
import { debounce } from "lodash";
import { Loader } from "../../../../components/atoms/Loader";
import { formatMoneyUSD } from "../../../../utils/currency";
import {
  RefreshedSku,
  RefreshedSkuItem,
  RefreshSkuItem,
} from "../../../../models/RefreshSku";
import { useToast } from "../../../../components/atoms/Toast";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { refreshProductsInCart } from "../../../../services/cartService";

interface ProductInfoModalProps {
  product: CustomerProduct;
  isShow: boolean;
  orderId: number;
  purchasedSkus: RefreshSkuItem[];
  onClose: () => void;
  onBuy: (product: RefreshSkuItem) => Promise<void>;
}

export const ProductInfoModal: React.FC<ProductInfoModalProps> = ({
  product,
  isShow,
  orderId,
  purchasedSkus,
  onClose,
  onBuy,
}) => {
  const toast = useToast();
  const minQuantity = 0;
  const maxQuantity = 1000000;
  const { handleError } = useErrorHandler();

  const [quantity, setQuantity] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] =
    useState<CustomerProduct>(product);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefreshProducts = useCallback(
    debounce(async (updatedSkus: RefreshSkuItem[]) => {
      try {
        const refreshedSku: RefreshedSkuItem = await refreshProductsInCart(
          orderId,
          purchasedSkus,
          updatedSkus
        );
        const refreshedProduct = toCustomerProduct(refreshedSku);
        setCurrentProduct(refreshedProduct);
      } catch (error) {
        handleError(error, toast, "atualizar o preço do produto");
      } finally {
        setIsLoading(false);
      }
    }, 1000),
    [orderId, purchasedSkus]
  );

  useEffect(() => {
    if (!isShow) return;

    const existentSku = purchasedSkus.find((item) => item.sku === product.sku);
    const currentQuantity = existentSku?.quantity ?? 1;

    setIsLoading(false);
    setQuantity(currentQuantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, purchasedSkus]);

  useEffect(() => {
    setCurrentProduct(product);
  }, [product]);

  const handleQuantityChange = (newQuantity: number) => {
    setIsLoading(true);
    setQuantity(newQuantity);
    const updatedSkus: RefreshSkuItem[] = [
      {
        productId: currentProduct.id,
        sku: currentProduct.sku,
        quantity: newQuantity,
      },
    ];
    debouncedRefreshProducts(updatedSkus);
  };

  const handleBuy = () => {
    setIsLoading(true);
    const prod: RefreshSkuItem = {
      productId: currentProduct.id,
      sku: currentProduct.sku,
      quantity: quantity,
    };
    onBuy(prod);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isShown={isShow} onClose={onClose} size="large">
      <h1 className="h4 text-muted">{currentProduct.name}</h1>
      <div className="d-flex justify-content-between">
        <h2 className="h4">
          {formatMoneyUSD(currentProduct.resellerPriceUSD)}
        </h2>
        <InputNumber
          name="quantity"
          id={`quantity-${currentProduct.id}`}
          value={quantity}
          minValue={minQuantity}
          maxValue={maxQuantity}
          onChange={handleQuantityChange}
        />
      </div>

      {currentProduct.details && (
        <>
          <div className="hr"></div>
          <p className="text-justify">{currentProduct.details}</p>
          <div className="hr"></div>
        </>
      )}

      <div className="w-100 d-flex justify-content-center gap-2">
        <button
          className="btn btn-secondary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancelar {isLoading && <Loader />}
        </button>
        <button
          className="btn btn-primary"
          onClick={handleBuy}
          disabled={isLoading}
        >
          Comprar {isLoading && <Loader />}
        </button>
      </div>
    </Modal>
  );
};
