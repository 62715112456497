import React, { Dispatch, SetStateAction } from "react";
import { Input } from "../../../../components/atoms/Input";
import { Select } from "../../../../components/atoms/Select";
import { CartFormData } from "../../models/CartFormData";
import { useForm } from "../../../../hooks/formHook";
import { paymentConditionMap } from "../../../../models/enums/PaymentCondition";
import { billingForMap } from "../../../../models/enums/BillingFor";
import { getAuthUser } from "../../../../utils/authUtils";
import { Card } from "../../../../components/mols/Card";
import { newOrderPaymentConditionMap } from "../../../../models/enums/NewOrderPaymentCondition";

interface CartHeaderProps {
  isRenewal: boolean;
  isHubspotQuote: boolean;
  formData: CartFormData;
  setFormData: Dispatch<SetStateAction<CartFormData>>;
}

export const CartHeader: React.FC<CartHeaderProps> = ({
  isRenewal,
  isHubspotQuote,
  formData,
  setFormData,
}) => {
  const { handleInputChange } = useForm({ setFormData });
  const userName = getAuthUser()?.name;

  return (
    <Card className="mb-2">
      <h1 className="h3">Carrinho de compras</h1>

      <form>
        <div className="row">
          {/* Linha 1 */}
          <div className="col col-md-2 col-sm-6 col-12">
            <Input
              label="Data"
              id="dateCreated"
              value={formData.dateCreated}
              disabled
            />
          </div>

          <div className="col col-md-3 col-sm-6 col-12">
            <Input
              label="Revenda"
              id="reseller"
              value={formData.reseller}
              disabled
            />
          </div>

          <div className="col col-md-4 col-sm-6 col-12">
            <Input
              label="Cliente"
              id="customer"
              value={`${formData.adobeCustomerId} - ${formData.customer}`}
              disabled
            />
          </div>

          <div className="col col-md-1 col-sm-6 col-12">
            <Input
              label="Prorata"
              type="number"
              id="prorata"
              value={formData.prorata}
              disabled
            />
          </div>

          <div className="col col-md-2 col-sm-6 col-12">
            <Input
              label="Aniversário"
              id="anniversary"
              value={formData.anniversary}
              disabled
            />
          </div>

          {/* Linha 2 */}
          <div className="col col-md-2 col-sm-6 col-12">
            <Input
              label="Tipo"
              id="isRenewal"
              value={formData.isRenewal ? "Renovação" : "Novo"}
              disabled
            />
          </div>

          <div className="col col-md-2 col-sm-6 col-12">
            <Input label="Nº MP" id="id" value={formData.id} disabled />
          </div>

          <div className="col col-md-3 col-sm-6 col-12">
            <Input
              label="Ped. Cliente"
              id="pedCustomer"
              value={formData.pedCustomer}
              onChange={handleInputChange}
            />
          </div>

          <div className="col col-md-3 col-sm-6 col-12">
            <Input
              label="Ped. Revenda"
              id="pedReseller"
              value={formData.pedReseller}
              onChange={handleInputChange}
            />
          </div>

          <div className="col col-md-2 col-sm-6 col-12">
            <Select
              label="Faturar para"
              options={Object.values(billingForMap)}
              id="billingFor"
              value={formData.billingFor}
              onChange={handleInputChange}
            />
          </div>

          {/* Linha 3 */}
          <div className="col col-md-3 col-sm-6 col-12">
            <Input label="Forma de pagamento" value="Boleto" disabled />
          </div>

          <div className="col col-md-4 col-sm-6 col-12">
            <Select
              label="Condições de faturamento"
              options={Object.values(
                isHubspotQuote
                  ? paymentConditionMap
                  : newOrderPaymentConditionMap
              )}
              id="paymentCondition"
              value={formData.paymentCondition}
              onChange={handleInputChange}
              disabled={isHubspotQuote}
            />
          </div>

          <div className="col col-md-5 col-sm-6 col-12">
            <Input label="Responsável pelo pedido" value={userName} disabled />
          </div>
        </div>
      </form>
    </Card>
  );
};
