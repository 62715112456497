import React, { useEffect, useState } from "react";
import { Modal } from "../../../../../components/mols/Modal";
import { OrderItem } from "../../../../../models/OrderItem";
import { ModalBody } from "./ModalBody";

interface CartModalProps {
  isShown: boolean;
  isFinishingCart: boolean;
  item: OrderItem | null;
  quantity: number;
  priceBRL: number;
  type: "quantity" | "price";
  onEditQuantity: (productId: number, quantity: number) => Promise<void>;
  onEditPrice: (
    productId: number,
    quantity: number,
    resellerPriceBRL: number
  ) => Promise<void>;
  onClose: () => void;
}

export const CartModal: React.FC<CartModalProps> = ({
  isShown,
  isFinishingCart,
  item,
  quantity: ammount,
  priceBRL,
  type,
  onEditQuantity,
  onEditPrice,
  onClose,
}) => {
  const [quantity, setQuantity] = useState<number>(ammount);
  const [totalBRL, setTotalBRL] = useState<number>(priceBRL);

  const handleClose = (): void => {
    onClose();
  };

  const handleSubmit = async (): Promise<void> => {
    const productId = item?.productId ?? 0;

    if (type === "price") await onEditPrice(productId, quantity, totalBRL);
    else if (type === "quantity") await onEditQuantity(productId, quantity);

    onClose();
  };

  useEffect(() => {
    setQuantity(ammount);
    setTotalBRL(priceBRL);
  }, [ammount, priceBRL]);

  if (!item) return null;

  return (
    <Modal
      title="Editar produto"
      isShown={isShown}
      onClose={handleClose}
      aria-label="Edição de item do carrinho"
    >
      <ModalBody
        item={item}
        quantity={quantity}
        totalBRL={totalBRL}
        type={type}
        isFinishingCart={isFinishingCart}
        onChangeQuantity={setQuantity}
        onChangePrice={setTotalBRL}
        onSave={handleSubmit}
        onClose={handleClose}
      />
    </Modal>
  );
};
