import React from "react";
import { Input } from "../../../../components/atoms/Input";

interface CustomerFilterProps {
  id: string;
  value: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilter: (evt: React.FormEvent<HTMLFormElement>) => void;
}

export const CustomerFilter: React.FC<CustomerFilterProps> = ({
  id,
  value,
  onInputChange,
  onFilter,
}) => {
  return (
    <form onSubmit={onFilter}>
      <div className="row">
        <div className="col col-10">
          <Input
            placeholder="Pesquisar por Cliente ou Customer ID"
            id={id}
            value={value}
            onChange={onInputChange}
          />
        </div>

        <div className="col col-2 py-2">
          <button type="submit" className="btn btn-primary d-block w-100">
            Pesquisar
          </button>
        </div>
      </div>
    </form>
  );
};
